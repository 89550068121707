import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import Signup from '../../../static/images/signup.svg';
import FaceBook from '../../../static/images/fb-logo.svg';
import Greater from '../../../static/images/greater-sign.svg';
import Google from '../../../static/images/google.svg';
import Linkedin from '../../../static/images/linkedin.svg';
import Back from '../../../static/images/back-arrow.svg';
import Next from '../../../static/images/next-arrow.svg';
import ModalContext from '../../contexts/ModalContext';
import Wrapper from '../shared/Wrapper';
import { linkedInLogin } from '../../services/firebaseService';

const ProcessingButton = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center align-middle">
      <svg
        className="animate-spin -inline-block w-6 h-6 border-4 rounded-full mr-2"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx={12}
          cy={12}
          r={10}
          stroke="currentColor"
          strokewidth={4}
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
      <span>{t('shared.buttons.processing')}</span>
    </div>
  );
};

const Body = () => {
  const { t } = useTranslation();
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);
  const [isLoadingFacebook, setLoadingFacebook] = useState(false);
  const [isLoadingEmail, setLoadingEmail] = useState(false);
  const { emitter, events } = useContext(ModalContext);
  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  const { user, loginWithGoogle, createWithEmail, loginWithFacebook } =
    useContext(UserContext);
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };

  const handleSignInWithFacebook = async () => {
    setLoadingFacebook(true);
    await loginWithFacebook();
    setLoadingFacebook(false);
  };

  React.useEffect(() => {
    if (user) {
      navigate('/app/dashboard');
    }
  }, [user]);

  const handleCreateWithEmail = async () => {
    setLoadingEmail(true);
    const res = await createWithEmail(loginData.username, loginData.password);
    if (res) {
      if (res.user) {
        toast.success('Account created successfully');
        setLoadingEmail(false);
        setLoginData({ username: '', password: '' });
      }
    } else {
      setLoadingEmail(false);
    }
  };

  const onChanger = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    <div className="bg-[#F2F5FA] w-full">
      <div className="flex container pt-[20px] md:pt-[30px]">
        <div className="w-full md:w-2/4 flex flex-col justify-center items-left ml-0 px-2 md:px-0 md:ml-6">
          <div className="flex flex-col w-full md:w-11/12">
            {/* <h2 className="text-[#3D41F1] font-semibold text-[28px] text-center md:text-[34px] md:text-left mt-6">
              {t('signup.title')}
            </h2>
            <p className="font-normal text-center md:text-left text-[14px] md:text-[18px] text-[#7C8296]">
              {t('signup.subtitle')}
            </p> */}
            <div className="mt-12 flex flex-col space-y-6 items-center">
              <button
                isLoading={isLoadingFacebook}
                onClick={handleSignInWithFacebook}
                className="bg-[#E7EFFF] w-full flex items-center justify-between px-9 text-[14px] md:text-[16.5px] text-center py-6 rounded-2xl text-white"
              >
                {' '}
                <span className="flex justify-center items-center space-x-5">
                  <img src={FaceBook} alt="facebook" />{' '}
                  <h3 className="text-[18px] font-medium text-[#32373F]">
                    Facebook
                  </h3>
                </span>{' '}
                <img src={Greater} alt="greater" />
              </button>
              <button
                isLoading={isLoadingGoogle}
                onClick={handleSignInWithGoogle}
                className="bg-[#E7EFFF] w-full flex items-center justify-between px-9 text-[14px] md:text-[16.5px] text-center py-6 rounded-2xl text-white"
              >
                {' '}
                <span className="flex justify-center items-center space-x-5">
                  <img src={Google} alt="facebook" />{' '}
                  <h3 className="text-[18px] font-medium text-[#32373F]">
                    Google
                  </h3>
                </span>{' '}
                <img src={Greater} alt="greater" />
              </button>
              <button
                className="bg-[#E7EFFF] w-full flex items-center justify-between px-9 text-[14px] md:text-[16.5px] text-center py-6 rounded-2xl text-white"
                onClick={() => {
                  linkedInLogin();
                }}
              >
                {' '}
                <span className="flex justify-center items-center space-x-5">
                  <img src={Linkedin} alt="facebook" />{' '}
                  <h3 className="text-[18px] font-medium text-[#32373F]">
                    Linkedin
                  </h3>
                </span>{' '}
                <img src={Greater} alt="greater" />
              </button>
            </div>

            <div className="flex items-center w-full mt-6 text-[#B1BBFF]">
              <div className="flex-grow w-4/12 border-t border-[#B1BBFF]" />
              <p className="w-4/12 text-center text-[16px] text-[#7C8296]">
                {t('signup.emailSigninTitle')}
              </p>
              <div className="flex-grow w-4/12 border-t border-[#B1BBFF]" />
            </div>

            <div className="flex flex-col w-full mt-6 space-y-4">
              <input
                onChange={(e) => onChanger(e)}
                name="username"
                className="bg-[#E7EFFF] rounded-lg border-[1px] border-[#B1BBFF] border-solid px-3 py-5 w-full placeholder:text-[#32373F] placeholder:text-[16px] text-[16px]  focus-visible:outline-none"
                placeholder={t('signup.emailPlaceholder')}
                input="text"
                type="email"
                value={loginData.username}
              />
              <input
                onChange={(e) => onChanger(e)}
                name="password"
                className="bg-[#E7EFFF]  rounded-lg border-[1px] border-[#B1BBFF] border-solid px-3 py-5 w-full placeholder:text-[#32373F] placeholder:text-[16px] text-[16px]  focus-visible:outline-none"
                placeholder={t('signup.passwordPlaceholder')}
                input="password"
                type="password"
                value={loginData.password}
              />
            </div>

            <button
              onClick={handleCreateWithEmail}
              className="bg-[#4258FF] text-white font-semibold text-[13px] w-full rounded-lg py-3 my-3"
            >
              {isLoadingEmail ? (
                <ProcessingButton />
              ) : (
                t('shared.buttons.signup')
              )}
            </button>

            <div className="flex items-center justify-between mt-8">
              <button className="text-[#3D41F1] flex items-center space-x-2 text-[12px] md:text-[16px] font-medium border-[1px] border-solid rounded-lg py-2 px-8">
                {' '}
                <img src={Back} alt="back" />{' '}
                <h4>{t('shared.buttons.back')}</h4>
              </button>
              <p className="text-[11px] md:text-[16px] text-[#9195A0]">
                {t('signup.accountExist')}{' '}
                <button
                  className="text-[#3D41F1] font-medium"
                  onClick={handleLogin}
                >
                  {t('signup.login')}
                </button>{' '}
              </p>
              <button className="bg-[#3D41F1] flex items-center space-x-2 text-white text-[12px] md:text-[16px] font-medium border-[1px] border-solid rounded-lg py-2 px-8">
                <h4>{t('shared.buttons.next')}</h4>{' '}
                <img src={Next} alt="next" />
              </button>
            </div>
            <p className="text-[#6A7683] text-[16px] my-24">
              {t('signup.footerDescription')}
            </p>
          </div>
        </div>
        <div className="hidden md:block w-2/4">
          <img src={Signup} alt="header" className="w-full" />
        </div>
      </div>
    </div>
  );
};

const BodyWithWrapper = () => (
  <Wrapper>
    <Body />
  </Wrapper>
);
export default BodyWithWrapper;
