import React from 'react'
import NavBar from '../components/signup/NavBar'
import Footer from '../components/global/Footer'
import Body from '../components/signup/Body'


export default function signup() {
    return (
        <>
            <NavBar />
            <Body />
            <Footer />
        </>
    )
}
