import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../../static/images/logo-main.svg';

export default function NavBar() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:flex-row justify-around container py-6">
      <div className="flex justify-center md:justify-start  space-x-2 items-center w-full md:w-3/12 ml-0 md:ml-6">
        <img src={logo} alt="logo" />
        <Link
          to="/"
          className="font-semibold  text-[18px] text-[#363C4B] hover:no-underline"
        >
          SimpleCV
        </Link>
      </div>
      <div className="w-full md:w-6/12 pr-0 md:pr-6 pt-6 md:pt-0 flex-col md:flex-row flex justify-center items-center space-x-0 md:space-x-4 space-y-2 md:space-y-0">
        <h3 className="text-[#262B33] text-center w-full  md:w-3/12 text-[14px] font-meduim">
          {' '}
          <span className="bg-[#DDE3EF] text-white text-[14px] rounded-full py-1 px-3 mr-1">
            1
          </span>{' '}
          {t('signup.navbar.step1')}
        </h3>
        <div className="flex-grow w-[40px] border-t border-[#DDE3EF]" />
        <h3 className="text-[#262B33] w-full  md:w-4/12 text-center text-[14px] font-meduim">
          {' '}
          <span className="bg-[#3D41F1] text-white text-[14px] rounded-full py-1 px-3 mr-1">
            2
          </span>{' '}
          {t('signup.navbar.step2')}
        </h3>
        <div className="flex-grow w-[40px]  border-t border-[#DDE3EF]" />
        <h3 className="text-[#9799B1] w-full  md:w-3/12 text-center text-[14px] font-meduim">
          {' '}
          <span className="bg-[#DDE3EF] text-white text-[14px] rounded-full py-1 px-3 mr-1">
            3
          </span>{' '}
          {t('signup.navbar.step3')}
        </h3>
      </div>
      <div className="w-full hidden md:w-3/12 md:flex mt-8 md:mt-0 justify-center md:justify-end items-center space-x-8 md:space-x-4 mr-0 md:mr-2">
        <Link to="/">X</Link>
      </div>
    </div>
  );
}
